import { type SelectOption } from '@mui/base';
import { Option } from '@mui/base/Option';
import { Select } from '@mui/base/Select';
import CloseIcon from '@assets/icons/close.svg';
import { GuestTagPill } from 'restaurantAdmin/components/guestTags/GuestTagPill/GuestTagPill';
import { type GuestTag } from 'restaurantAdmin/restaurants/apiHelpers';
import styles from './GuestTagFilterSelect.scss';
import { useReservationServiceContext } from './state/ReservationServiceContext';

export const GuestTagFilterSelect = () => {
  const { guestTags, guestTagFilters, handleSelectGuestTagFilters } =
    useReservationServiceContext();

  const handleTagSelection = (_e: unknown, newGuestTags: GuestTag[]) => {
    handleSelectGuestTagFilters(newGuestTags);
  };

  const renderSelectedGuestTagFilters = (options: SelectOption<GuestTag>[]) => {
    if (!options || !options.length) {
      return 'Select guest tags to filter on';
    }
    return options.map((option) => (
      <GuestTagPill
        key={`${option.value.title}-filter`}
        title={option.value.title}
        category={option.value.category}
      />
    ));
  };

  return (
    <form className={styles.container}>
      <Select
        multiple
        name="guest-tag-filter-select"
        onChange={handleTagSelection}
        value={guestTagFilters}
        slotProps={{
          root: { className: styles.selectButton },
          popup: { disablePortal: true, style: { zIndex: 10 } },
          listbox: { className: styles.listbox },
        }}
        renderValue={renderSelectedGuestTagFilters}
      >
        {guestTags.map((tag) => (
          <Option key={tag.id + tag.title} value={tag} label={tag.title}>
            <GuestTagPill title={tag.title} category={tag.category} />
          </Option>
        ))}
      </Select>
      <button
        className={styles.clearButton}
        aria-label="clear guest tag filters"
        onClick={(e) => {
          e.preventDefault();
          handleSelectGuestTagFilters([]);
        }}
      >
        <CloseIcon className={styles.icon} />
      </button>
    </form>
  );
};
